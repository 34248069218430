import React, { useEffect } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

import Layout from '../layouts/layout'
import Seo from '../components/seo'
import ContentContainer from '../components/UI/ContentContainer'
import HomeButton from '../components/UI/Buttons/HomeButton'
import { BUTTON_COLOR, BUTTON_SIZE } from '../support'

// For more information: https://www.gatsbyjs.com/docs/how-to/adding-common-features/add-404-page/
const Error404 = () => {
  useEffect(() => {
    navigate('/')
  }, [])
  return (
    <Layout>
      <Seo title="Not Found" />
      <ContentContainer>
        <h1 className="text-center">This page doesn't exist!</h1>
        <p className="text-center">
          It's probably something we've broken, but please check 
          <br />the link to make sure the URL is spelt correctly.
        </p>
        <ButtonWrapper>
          <HomeButton
            height={BUTTON_SIZE.HEIGHT_S}
            padding={BUTTON_SIZE.PADDING_S}
            fontSize={BUTTON_SIZE.FONT_SIZE_S}
            background={BUTTON_COLOR.GREEN}
            backgroundHover={BUTTON_COLOR.GREEN_HOVER}
            color={BUTTON_COLOR.TEXT_COLOR_WHITE}
            text="Go To Homepage"
            handleClick={() => navigate('/')}
          />
          <HomeButton
            height={BUTTON_SIZE.HEIGHT_S}
            padding={BUTTON_SIZE.PADDING_S}
            fontSize={BUTTON_SIZE.FONT_SIZE_S}
            background={BUTTON_COLOR.GRAY}
            backgroundHover={BUTTON_COLOR.GRAY_HOVER}
            color={BUTTON_COLOR.TEXT_COLOR_BLUE}
            text="Get in Contact"
            handleClick={() => navigate('/contact')}
          />
        </ButtonWrapper>
      </ContentContainer>
    </Layout>
  )
}

const ButtonWrapper = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: center;
  >button {
    margin: 0 12px;
  }
`

export default Error404
